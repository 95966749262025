import './App.css';
import React, { useState, useEffect } from 'react';

function App() {

  useEffect(() => {
    // Update the document title using the browser API
    let elm = document.getElementById('answerBox');
    elm.addEventListener("keypress", function(event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        onSubmit();
      }
    });
  });

  function onSubmit () {
    let useless = window.atob('UjBGTlJTQk9TVWRJVkE9PQ==');
    let elm = document.getElementById('answerBox');
    useless = window.atob(useless);
    let answer = elm.value.toUpperCase();

    if (answer.replace(/\s/g, '') == useless.replace(/\s/g, '')) {
      let divelm = document.getElementById('wrapper');
      divelm.classList.add("correct");

      setTimeout(() => {
        divelm.remove();
        window.location.href='https://gamenight.zachwerden.com';
      }, 1000);

      

      return;
    }

    elm.value = '';
    let divelm = document.getElementById('wrapper');
    divelm.classList.add("wrong");
    window.navigator.vibrate(300);
    setTimeout(() => {
      divelm.classList.remove("wrong");
    }, 1000);
    
  }

  return (
    <div className="App">
      <header className="App-header">
        <p className='question'>
          cNn you solve tUis siZple code
        </p>
        <p className='code'>
          TNZR AVTUG
        </p>
        <div className="form">
          <div id="wrapper" className="webflow-style-input">
            <input id="answerBox" autoFocus className="" type="text" placeholder=""></input>
            <button type="submit"><i className="icon ion-android-arrow-forward" onClick={onSubmit}></i></button>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
